import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { motion } from 'framer-motion';

const ContactPage = () => {
    const titlestrokeani = {
        duration: 1.65,
        ease: [0.19, 1.0, 0.22, 1.0],
        delay: 1,
    };

    return (
        <Layout>
            <Seo title="Contact" description="Contact Quokka Finance" />
            <div className="app-title">
                <div className="wrapper">
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        transition={{
                            duration: 0.45,
                            delay: 0.45,
                            ease: [0.23, 0.46, 0.4, 1],
                        }}
                        variants={{
                            visible: { opacity: 1, y: 0 },
                            hidden: { opacity: 0, y: -15 },
                        }}
                    >
                        <div className="title">
                            <h1>Contact</h1>
                        </div>
                    </motion.div>
                </div>
                <div className="titlestroke">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        viewBox="0 0 1983.911 218.409"
                    >
                        <motion.path
                            d="M7.57 7.57s427.09 139.916 918.524 188.554c355.142 35.149 744.9 22.1 1028.069-128.2"
                            fill="none"
                            stroke="#c2d83d"
                            strokeLinecap="round"
                            strokeWidth="12"
                            initial={{ pathLength: 0 }}
                            animate={{ pathLength: 1 }}
                            transition={titlestrokeani}
                        />
                    </svg>
                    <motion.div
                        initial={{ offsetDistance: '0%', scale: 2.5 }}
                        animate={{ offsetDistance: '100%', scale: 1 }}
                        transition={titlestrokeani}
                    />
                </div>
            </div>
            <div className="app-contact">
                <div className="wrapper">
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        transition={{
                            duration: 0.85,
                            delay: 0.45,
                            ease: [0.23, 0.46, 0.4, 1],
                        }}
                        variants={{
                            visible: { opacity: 1, y: 0 },
                            hidden: { opacity: 0, y: -15 },
                        }}
                    >
                        <div className="contacttxt">
                            <div>
                                <h1>
                                    Get in <span>Touch</span>
                                </h1>
                            </div>
                            <div>
                                <p>
                                    <strong>Head Office</strong>
                                    <br />
                                    Level 2, 25 Richardson St
                                    <br />
                                    West Perth WA 6005
                                    <br />
                                    enquiry@quokkafinance.com.au
                                    <br />
                                    Ph: <a href="tel:1800786552">1800 QUOKKA</a>
                                </p>
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>
        </Layout>
    );
};

export default ContactPage;
